import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/MainNavbar/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/WhatsappFloatingButton/index.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"PT_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"jakarta\"}");
